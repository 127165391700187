export default {
  title: {
    t1: "Sayohat haqida",
    t2: "Siz buni bilasizmi",
    t3: "Sayohat tafsilotlari",
    t4: "Bizning tariflarimiz",
  },
  about: {
    a1: "14 kun",
    a2: "3 ta shahar",
    a3: "1 600$ dan",
  },
  messages: {
    more: "Batafsil ma'lumot",
    send: "Yuborish",
    subTitle:
      "Saudiya Arabistoniga sayohatlar Saudiya Arabistonidagi muqaddas joylar",
    bookNow: "Band qilmoq",
    desc: "Tavsif",
    program: "Dastur",
    images: "Tasvirlar",
    copy: "Mualliflik huquqi",
    day: "Kun",
    address: "Manzil",
  },
  form: {
    firstName: "Ism",
    lastName: "Familiya",
    phoneNumber: "Telefon raqami",
    passengers: "Yo'lovchilar",
    flightDate: "Parvoz sanasi",
    price: "Kishi boshiga narx",
    email: "Elektron pochta",
    message: "Sizning xabarlaringiz",
    close: "Yopmoq",
    submit: "Jo'natmoq",
    title: "Payme dan to'lash",
    label1: "Karta raqamingizni kiriting",
    label2: "Karta amal qilish muddati",
    label3: "Pul miqdori",
    success: "Toʻlovingiz muvaffaqiyatli amalga oshirildi",
    sMessage: "Muvaffaqiyatli yuborildi",
    serverError: "Server xatosi",
    error: "Qayerdadir xatolik yuz berdi!",
  },
  details: {
    d1: {
      title: "Qo'llanmalar",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d2: {
      title: "Bizdan viza",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d3: {
      title: "Tibbiy sug'urta",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d4: {
      title: "Qulay avtobuslar",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d5: {
      title: "Madinaga sayohatlar",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d6: {
      title: "Makkadagi faoliyat",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
  },
  nav: {
    home: "Bosh sahifa",
    about: "Sayohat haqida",
    reasons: "Sabablar",
    details: "Tafsilotlar",
    tariff: "Tariflar",
    contact: "Kontaktlar",
  },
  banner: {
    s1: "Kichik haj",
    s2: "Umra",
    s3: "Umra (Kichik haj) — musulmonlarning Makka va Madinaga ziyorat qilishlari boʻlib, haj uchun ajratilgan oylar bundan mustasno, yilning istalgan vaqtida amalga oshiriladi.",
  },
  reasons: {
    r1: {
      title: "Saudiya Arabistoni Qirolligi haqida",
      section:
        "Saudiya Arabistoni Qirolligi Yaqin Sharqdagi eng yirik davlat va Yaqin Sharq va Afrika mamlakatlari orasida aholi soni bo'yicha to'rtinchi o'rinda turadi. 2020 yil holatiga ko'ra, Saudiya Arabistoni aholisi 35 million kishini tashkil qiladi.",
    },
    r2: {
      title: "Saudiya haqida",
      section:
        "Saudiyaliklarning 80 foizi o‘nta yirik shahar markazida – Ar-Riyod, Jidda, Makka, Madina, Xufuf, Toif, Xubar, Yanbu, Zahron, Dammamda istiqomat qiladi. Mamlakat aholisining bir qismini immigrantlar, jumladan G‘arb, Hindiston va Osiyodan kelganlar tashkil qiladi. Ular Ar-Riyod, Jidda, Yanbu va Zahron shaharlarida yashaydilar",
    },
    r3: {
      title: "Saudiya Arabistonidagi din",
      section:
        "Davlat dini Islomdir. Saudiya Arabistoni ikki ziyoratgoh mamlakati deb nomlanadi, chunki bu erda Islomning ikkita asosiy muqaddas shaharlari - Makka va Madina joylashgan. Har yili mamlakatga Haj ziyoratiga kelgan millionlab musulmonlar jalb qilinadi. Makka va Madina shaharlariga musulmon bo‘lmaganlar kirishi taqiqlangan.",
    },
  },
};
