import { createApp } from "vue";
import App from "./App.vue";
import router from "@/routes";
import i18n from "@/locales";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);
app.use(router);
app.use(i18n);
app.use(Toast, {
  position: "top-right",
  timeout: 3210,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
});
app.mount("#app");
