export default {
  title: {
    t1: "О туре",
    t2: "Знаете ли вы, что",
    t3: "Детали тура",
    t4: "Наши тарифы",
  },
  about: {
    a1: "14 дней",
    a2: "3 города",
    a3: "от 1 600 $",
  },
  messages: {
    more: "Больше информации",
    send: "Отправить",
    subTitle: "Туры в Саудовскую Аравию Святые места в Саудовской Аравии",
    bookNow: "Забронируйте",
    desc: "Описание",
    program: "Программа",
    images: "Картинки",
    copy: "Авторские права",
    day: "День",
    address: "Адрес",
  },
  form: {
    firstName: "Имя",
    lastName: "Фамилия",
    phoneNumber: "Номер телефона",
    passengers: "Пассажиры",
    flightDate: "Дата полета",
    price: "Цена за человека",
    email: "Эл. адрес",
    message: "Ваши сообщения",
    close: "Закрывать",
    submit: "Отправить",
    title: "Оплата через Payme",
    label1: "Введите номер карты",
    label2: "Срок действия карты",
    label3: "Количество денег",
    success: "Ваш платеж успешно обработан",
    sMessage: "Успешно отправлено",
    serverError: "Ошибка сервера",
    error: "Что-то пошло не так!",
  },
  details: {
    d1: {
      title: "Путеводитель",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d2: {
      title: "Виза от нас",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d3: {
      title: "Медицинская страховка",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d4: {
      title: "Комфортабельные автобусы",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d5: {
      title: "Экскурсии в Медине",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d6: {
      title: "Мероприятия в Мекке",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
  },
  nav: {
    home: "Домашняя страница",
    about: "О туре",
    reasons: "Причины для тура",
    details: "Детали тура",
    tariff: "Тарифы",
    contact: "Контакты",
  },
  banner: {
    s1: "Малый Хадж",
    s2: "Умра",
    s3: "Умра (Малый Хадж) – это паломничество мусульман в Мекку и Медину, совершаемое в любое время года за исключением месяцев, отведенных для Хаджа.",
  },
  reasons: {
    r1: {
      title: "О Королевстве Саудовская Аравия",
      section:
        "«Королевство Саудовская Аравия — крупнейшее государство Ближнего Востока и четвертое по численности населения среди стран Ближнего Востока и Африки. По состоянию на 2020 год население Саудовской Аравии составляет 35 млн человек.»,",
    },
    r2: {
      title: "О Саудовской Аравии",
      section:
        "80% саудовцев живут в десяти крупных городских центрах — Эр-Рияде, Джидде, Мекке, Медине, Хуфуфе, Таифе, Хубаре, Янбу, Дахране, Даммаме. Часть населения страны составляют иммигранты, в том числе с Запада, Индии и Азии. Они живут в городах Эр-Рияд, Джидда, Янбу и Дахран.",
    },
    r3: {
      title: "Религия в Саудовской Аравии",
      section:
        "Государственная религия – ислам. Саудовскую Аравию называют «страной двух святынь», так как здесь находятся два главных священных города ислама – Мекка и Медина. Ежегодно страна привлекает миллионы мусульман, которые приезжают, чтобы совершить паломничество - хадж. Не мусульманам въезд в города Мекка и Медина запрещен.",
    },
  },
};
