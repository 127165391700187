<template>
  <swiper
    :slidesPerView="1"
    :spaceBetween="20"
    :grabCursor="true"
    :loop="true"
    :loopFillGroupWithBlank="true"
    :lazy="true"
    :modules="modules"
    :pagination="{
      clickable: true,
    }"
    :breakpoints="{
      '640': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '768': {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      '1024': {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    }"
    :navigation="true"
    class="mySwiper"
    id="flavoursContainer"
  >
    <swiper-slide
      ><img
        src="https://lh5.googleusercontent.com/ILzM0SqdZaMfMHdO6LMOQpmxaX8gULG4DBKW3zAuXpMNO6AMSQFa3MpTsB-_H2o9--hDlH0hO1YlABCTVUQFGamAUcLdSLqf8-05ZbnPjBw9kCDMyYpHvyo72e0Z29iJ1FriqE6K"
        alt=""
        class="swiper-lazy"
    /></swiper-slide>
    <swiper-slide
      ><img src="@/assets/1.jpg" alt="" class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img src="@/assets/2.webp" alt="" class="swiper-lazy"
    /></swiper-slide>
    <swiper-slide
      ><img src="@/assets/3.jpg" alt="" class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img src="@/assets/4.jpg" alt="" class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img src="@/assets/third.jpg" alt="" class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img
        src="@/assets/second.jpg"
        alt=""
        class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img
        src="https://uzreport.news/fotobank/image/2cb17e66a8832d4f71adc95fea5e0adb.jpeg"
        alt=""
        class="swiper-lazy" /></swiper-slide
    ><swiper-slide
      ><img
        src="https://lh6.googleusercontent.com/FsAmRRY0besiKnA3FgZ5w0jfz1YzjAi4-Azs79d12kZLsMIUGmZ-1_MfURh-mIhqI0A6RX3Y3wZBjwTDRI2gLypZPOn4Z_BBZTtEguCvyqzMgYEKs9R-5W7SdPGSnSIcGBcYAGpB"
        alt=""
        class="swiper-lazy"
    /></swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/lazy";

import "@/assets/css/global.css";
import { Lazy } from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Lazy],
      fetchTimeInterval: null,
    };
  },
  methods: {
    autoScroll() {
      const flavoursContainer = document.getElementById("flavoursContainer");
      const flavoursScrollWidth = flavoursContainer.scrollWidth;
      //
      this.fetchTimeInterval = setInterval(() => {
        if (flavoursContainer.scrollLeft !== flavoursScrollWidth) {
          flavoursContainer.scrollTo(flavoursContainer.scrollLeft + 1, 0);
        }
      }, 12);
    },
  },
  mounted() {
    this.autoScroll();
  },
  unmounted() {
    clearInterval(this.fetchTimeInterval);
  },
};
</script>

<style scoped></style>
