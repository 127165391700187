export default {
  title: {
    t1: "About Tour",
    t2: "Did you know that",
    t3: "Tour Details",
    t4: "Our Tariffs",
  },
  about: {
    a1: "14 days",
    a2: "3 towns",
    a3: "from 1 600 $",
  },
  messages: {
    more: "More Info",
    send: "Send Message",
    subTitle: "Tours to Saudi Arabia Holy places in Saudi Arabia",
    bookNow: "Book now",
    desc: "Description",
    program: "Program",
    images: "Images",
    copy: "Copyright",
    day: "Day",
    address: "Address",
  },
  form: {
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    passengers: "Passengers",
    flightDate: "Flight Date",
    price: "Price per person",
    email: "Email",
    message: "Your Messages",
    close: "Close",
    submit: "Submit",
    title: "Pay from Payme",
    label1: "Enter your card number",
    label2: "Card validity period",
    label3: "Amount of money",
    success: "Your payment has been processed successfully",
    sMessage: "Success",
    error: "Something went wrong!",
    serverError: "Server Error",
  },
  details: {
    d1: {
      title: "Visitor's guide",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d2: {
      title: "Visa from us",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d3: {
      title: "Medical Insurance",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d4: {
      title: "Comfortable Buses",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d5: {
      title: "Excursions in Medina",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
    d6: {
      title: "Activities in Makkah",
      section:
        "Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum decore te sed. Elitr scripta ocurreret qui ad.",
    },
  },
  nav: {
    home: "Home",
    about: "About Tour",
    reasons: "Reasons to Tour",
    details: "Tour Details",
    tariff: "Tariffs",
    contact: "Contact Us",
  },
  banner: {
    s1: "Small Hajj",
    s2: "Umra",
    s3: "Umra (Small Hajj) is a Muslim pilgrimage to Mecca and Medina, performed at any time of the year, with the exception of the months set aside for the Hajj.",
  },
  reasons: {
    r1: {
      title: "About Kingdom of Saudi Arabia",
      section:
        "The Kingdom of Saudi Arabia is the largest country in the Middle East and the fourth most populous among the countries of the Middle East and Africa. As of 2020, the population of Saudi Arabia is 35 million people.",
    },
    r2: {
      title: "About Saudi",
      section:
        "80% of Saudis live in ten major urban centers - Riyadh, Jeddah, Mecca, Medina, Khufuf, Taif, Khubar, Yanbu, Dhahran, Dammam. Part of the country's population is made up of immigrants, including those from the West, India and Asia. They live in the cities of Riyadh, Jeddah, Yanbu and Dhahran",
    },
    r3: {
      title: "Religion in Saudi Arabia",
      section:
        'The state religion is Islam. Saudi Arabia is called "the country of two shrines", as it is home to two of the main holy cities of Islam - Mecca and Medina. Every year, the country attracts millions of Muslims who come to make the Hajj pilgrimage. Non-Muslims are not allowed to enter the cities of Mecca and Medina.',
    },
  },
};
