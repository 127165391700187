<template>
  <div class="hello">
    <form @submit.prevent="" class="mt-5">
      <div
        class="section-heading text-center mb-4 d-flex justify-content-center"
      >
        <img
          src="https://cdn.paycom.uz/documentation_assets/payme_02.png"
          alt=""
        />
        <h2>{{ $t("form.title") }}</h2>
      </div>
      <div class="w-30 mx-auto">
        <transition name="fade" mode="out-in">
          <div v-if="!isCardFull" class="pay-card">
            <div class="d-flex flex-column gap-1 mb-3">
              <label class="">{{ $t("form.label1") }}</label>
              <input
                v-model="cardNumber"
                type="text"
                maxlength="19"
                class="form-control px-2"
                placeholder="XXXX XXXX XXXX XXXX"
              />
            </div>
            <div class="d-flex flex-column gap-1 mb-3">
              <label class="">{{ $t("form.label2") }}</label>
              <input
                v-model="expirePeriod"
                @keydown="addSlash"
                type="text"
                class="form-control px-2"
                maxlength="5"
                placeholder="MM/YY"
              />
            </div>
            <div class="d-flex flex-column gap-1 mb-3 position-relative">
              <label class="">{{ $t("form.label3") }}</label>
              <input type="text" class="form-control" v-model="price" />
              <span class="price-span position-absolute">UZS</span>
            </div>
            <button
              @click="submitCard"
              class="w-100 btn mt-3"
              style="background: #33cccc"
              :disabled="!isFull"
            >
              <img
                src="https://cdn.paycom.uz/documentation_assets/payme_02.png"
                style="width: 24px; margin-right: 4px"
                alt=""
              />
              Davom etish
            </button>
          </div>
          <div v-else class="mb-3">
            <h6 class="">Tasdiqlash kodini kiriting</h6>
            <small class="fs-8"
              >+{{ changedPhoneNumber }} raqamiga SMS orqali yuborilgan 6 sonli
              ko’dni kiriting</small
            >
            <div class="mb-2">
              <input
                v-model="telCode"
                type="text"
                maxlength="6"
                class="pass form-control mt-2 px-2"
                placeholder="_ _ _ _ _ _"
              />
            </div>
            <button
              @click="toggleCard"
              class="w-100 btn mt-3"
              style="background: #33cccc"
              :disabled="!isCodeFull"
            >
              <img
                src="https://cdn.paycom.uz/documentation_assets/payme_02.png"
                style="width: 24px; margin-right: 4px"
                alt=""
              />
              Tasdiqlash
            </button>
          </div>
        </transition>
      </div>
    </form>
  </div>
</template>

<script>
import customAxios from "@/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  data() {
    return {
      isCardFull: false,
      price: "",
      telCode: "",
      cardNumber: "",
      expirePeriod: "",
      paymeToken: "",
    };
  },
  computed: {
    changedPhoneNumber() {
      if (!this.phoneNumber) return "";
      return (
        this.phoneNumber.substr(0, 3) +
        " " +
        this.phoneNumber.substr(3, 2) +
        " " +
        this.phoneNumber.substr(5)
      );
    },
    isFull() {
      return (
        this.filteredCardNumber?.length === 16 &&
        this.filteredSlash?.length === 4 &&
        this.price?.length
      );
    },
    numPrice() {
      if (this.price) {
        return Number(this.cleanSpace(this.price)) * 100;
      } else return 0;
    },
    isCodeFull() {
      return this.telCode?.length === 6;
    },
    filteredCardNumber() {
      return this.cardNumber.replace(/\s/g, "");
    },
    filteredSlash() {
      return this.expirePeriod.replace(/\//g, "");
    },
  },
  methods: {
    async toggleCard() {
      const res = await customAxios.post("/payme/card/verify/", {
        id: 123,
        params: {
          token: this.paymeToken,
          code: this.telCode,
        },
      });
      if (res.data?.error) {
        toast.error(res.data.error?.message);
      } else {
        try {
          const res = await customAxios.post("/payme/payment/", {
            id: 123,
            params: {
              token: res.data?.result?.card?.token,
              amount: this.numPrice,
              account: {
                order_id: 123,
              },
            },
          });
          if (res.data?.error) {
            toast.error(res.data.error.message);
          } else {
            this.price = "";
            this.telCode = "";
            this.cardNumber = "";
            this.expirePeriod = "";
            this.paymeToken = "";
            toast.success(this.$t("form.success"));
          }
          this.isCardFull = false;
        } catch (e) {
          toast.error(this.$t("form.error"));
        }
      }
    },
    async submitCard() {
      const res = await customAxios.post("/payme/card/create/", {
        id: 123,
        params: {
          card: {
            number: this.filteredCardNumber,
            expire: this.filteredSlash,
          },
          amount: this.numPrice,
          save: true,
        },
      });
      if (res.data?.error) {
        toast.error(res.data.error?.message);
      } else {
        this.isCardFull = true;
        this.paymeToken = res.data?.token;
        this.phoneNumber = res.data?.result?.phone;
      }
    },
    addSlash(e) {
      const key = e.keyCode || e.charCode;
      if (key !== 8 && key !== 46) {
        if (this.expirePeriod.length == 2) {
          this.expirePeriod = this.expirePeriod + "/";
        }
      }
      if ((key == 8 || key == 46) && this.expirePeriod.length === 4) {
        this.expirePeriod = this.expirePeriod.slice(0, 3);
      }
    },
    addPriceSpaces(num) {
      let number;
      number = num?.toString().replaceAll(" ", "");
      return Number(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    cleanSpace(str, char = "") {
      return str?.replace(/\s/g, char);
    },
  },
  watch: {
    cardNumber(val) {
      this.cardNumber = val
        .replace(/[^\dA-Z]/g, "")
        .replace(/(.{4})/g, "$1 ")
        .trim();
    },
    price(val) {
      this.price = this.addPriceSpaces(val);
    },
  },
};
</script>

<style scoped>
.section-heading img {
  width: 44px;
  margin-right: 1rem;
}
.hello {
  overflow-y: hidden;
}
.btn.w-100 {
  background: var(--sub-color);
  color: #fff;
}
button:disabled {
  cursor: not-allowed;
}
.w-30 {
  width: 300px;
}
.wrapper {
  padding: 3rem 4rem 0;
}
.pass {
  letter-spacing: 4px;
}
.price-span {
  right: 10%;
  bottom: 10%;
}
/* .fade-enter-from,
.fade-leave-to {
  transform: translateY(-100%);
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.8s ease;
} */

@media (max-width: 578px) {
  .wrapper {
    padding: 0 2rem;
  }
  .section-heading {
    align-items: center;
    justify-content: center;
  }
  .section-heading h2 {
    font-size: 1.4rem;
  }
  .section-heading img {
    height: 42px;
  }
}
</style>
