<template>
  <!-- ***** Preloader Start ***** -->
  <!-- <div id="preloader">
    <div class="jumper">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div> -->
  <!-- ***** Preloader End ***** -->

  <!-- Header -->
  <base-navbar></base-navbar>

  <!-- Page Content -->
  <!-- Banner Starts Here -->
  <div class="main-banner header-text position-relative" id="top">
    <div class="Modern-Slider">
      <!-- Item -->
      <div class="item item-1">
        <div class="img-fill">
          <div class="text-content">
            <h5
              style="
                color: #fea34e;
                font-weight: 700;
                font-size: 1.4rem;
                text-transform: uppercase;
              "
            >
              {{ $t("banner.s1") }}
            </h5>
            <h3 class="text-white" style="font-size: 2.2rem; font-weight: 700">
              {{ $t("banner.s2") }}
            </h3>
            <p style="font-size: 16px; position:  z-index: 11">
              {{ $t("banner.s3") }}
            </p>
            <!-- <a href="exchange.html" class="filled-button">Exchange Programs</a>
            <a href="education.html" class="filled-button mt-2 mt-md-0"
              >Education Abroad</a
            > -->
          </div>
        </div>
        <img
          src="@/assets/logo.png"
          class="d-none d-sm-inline-block position-absolute"
          style="width: 26rem; top: 22%; right: 15%; z-index: 10"
          alt=""
        />
      </div>
      <!-- // Item -->
      <!-- Item -->
      <!-- <div class="item item-2">
        <div class="img-fill">
          <div class="text-content">
            <h5
              style="
                color: #fea34e;
                font-weight: 700;
                font-size: 1.4rem;
                text-transform: uppercase;
              "
            >
              {{ $t("banner.s1") }}
            </h5>
            <h3 class="text-white" style="font-size: 2.2rem; font-weight: 700">
              {{ $t("banner.s2") }}
            </h3>
            <p>
              {{ $t("banner.s3") }}
            </p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- Banner Ends Here -->
  <swipper-demo></swipper-demo>

  <div class="services" id="about-tour">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-heading">
            <h2>{{ $t("title.t1") }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <img src="@/assets/svg/calendar.svg" alt="" />
            <h3 class="mt-3 mb-2 bluish">{{ $t("about.a1") }}</h3>
            <p>10 days Makkah</p>
            <p>4 days Madina</p>
          </div>
        </div>
        <div class="col-md-4 my-md-0 my-4">
          <div class="text-center">
            <img src="@/assets/svg/building.svg" alt="" />
            <h3 class="mt-3 mb-2 bluish">{{ $t("about.a2") }}</h3>
            <p>Makkah, Madina, Jiddah</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="text-center">
            <img src="@/assets/svg/price.svg" alt="" />
            <h3 class="mt-3 mb-2 bluish">{{ $t("about.a3") }}</h3>
            <p>Choose which tariff you would like to go</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="services container">
    <img src="@/assets/map.png" class="w-100 rounded shadow-img" alt="" />
  </div>

  <!--  -->

  <div class="services" id="reason">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-heading">
            <h2>{{ $t("title.t2") }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section class="">
    <div class="container pb-2">
      <article class="postcard light red">
        <a class="postcard__img_link" href="#">
          <img
            class="postcard__img"
            src="@/assets/first.png"
            alt="Image Title"
          />
        </a>
        <div class="postcard__text t-dark">
          <h1 class="postcard__title red">
            <a href="#">{{ $t("reasons.r1.title") }}</a>
          </h1>
          <div class="postcard__subtitle small"></div>
          <div class="postcard__bar"></div>
          <div class="postcard__preview-txt">
            {{ $t("reasons.r1.section") }}
          </div>
        </div>
      </article>
      <article class="postcard light green">
        <a class="postcard__img_link" href="#">
          <img
            class="postcard__img"
            src="@/assets/second.jpg"
            alt="Image Title"
          />
        </a>
        <div class="postcard__text t-dark">
          <h1 class="postcard__title green">
            <a href="#">{{ $t("reasons.r2.title") }}</a>
          </h1>
          <div class="postcard__subtitle small"></div>
          <div class="postcard__bar"></div>
          <div class="postcard__preview-txt">
            {{ $t("reasons.r2.section") }}
          </div>
        </div>
      </article>
      <article class="postcard light yellow">
        <a class="postcard__img_link" href="#">
          <img
            class="postcard__img"
            src="@/assets/third.jpg"
            alt="Image Title"
          />
        </a>
        <div class="postcard__text t-dark">
          <h1 class="postcard__title yellow">
            <a href="#">{{ $t("reasons.r3.title") }}</a>
          </h1>
          <div class="postcard__subtitle small"></div>
          <div class="postcard__bar"></div>
          <div class="postcard__preview-txt">
            {{ $t("reasons.r3.section") }}
          </div>
        </div>
      </article>
    </div>
  </section>

  <div class="services" id="detail">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-heading">
            <h2>{{ $t("title.t3") }}</h2>
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/guide.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d1.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/visa.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d2.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/medical.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d3.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/bus.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d4.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/excursion.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d5.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
        <div class="col-md-4">
          <div class="box-part text-center">
            <img src="@/assets/svg/excursion.svg" class="fa" alt="" />

            <div class="title">
              <h4>{{ $t("details.d6.title") }}</h4>
            </div>

            <!-- <div class="text">
              <span
                >Lorem ipsum dolor sit amet, id quo eruditi eloquentiam. Assum
                decore te sed. Elitr scripta ocurreret qui ad.</span
              >
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="services" id="tariff">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12">
          <div class="section-heading">
            <h2>{{ $t("title.t4") }}</h2>
          </div>
        </div>
        <div class="col-md-4 plan mb-3 mb-md-0">
          <div class="card bg-light text-center mb-3">
            <div class="card-header">Economy</div>
            <div class="card-body">
              <h5 class="card-title mt-2">From 1600$</h5>
              <button
                @click="changePage('economy')"
                class="w-100 btn btn-secondary mt-2"
              >
                {{ $t("messages.more") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4 plan mb-3 mb-md-0">
          <div class="card border-primary mb-3 text-center">
            <div class="card-header">Comfort</div>
            <div class="card-body">
              <h5 class="card-title mt-2">From 1900$</h5>
              <button
                @click="changePage('comfort')"
                class="w-100 btn btn-primary mt-2"
              >
                {{ $t("messages.more") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4 plan">
          <div class="card border-success mb-3 text-center">
            <div class="card-header">VIP</div>
            <div class="card-body text-success">
              <h5 class="card-title mt-2">From 2500$</h5>
              <button
                @click="changePage('vip')"
                class="w-100 btn btn-success mt-2"
              >
                {{ $t("messages.more") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <PaymeForm />
    </div>
  </div>
  <!-- Footer Starts Here -->
  <BaseFooter />
</template>

<script>
import BaseNavbar from "@/components/BaseNavbar.vue";
import PaymeForm from "@/components/PaymeForm.vue";
import BaseFooter from "@/components/BaseFooter.vue";

import SwipperDemo from "@/components/SwipperDemo.vue";
export default {
  name: "App",
  components: {
    BaseNavbar,
    SwipperDemo,
    PaymeForm,
    BaseFooter,
  },
  methods: {
    changePage(val) {
      this.$router.push({ name: "plan", params: { status: val } });
    },
  },
  mounted() {
    const year = document.getElementById("year");
    if (year) year.textContent = new Date().getFullYear();
  },
};
</script>

<style scoped>
.card {
  cursor: pointer;
}
.a-box {
  display: inline-block;
  width: 240px;
  text-align: center;
}

.img-container {
  height: 230px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  transform: skew(0deg, -13deg);
  height: 250px;
  margin: -35px 0px 0px -70px;
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  transform: skew(0deg, 13deg);
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 250px;
  width: 200px;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
  line-height: 19px;
  font-size: 14px;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #04bcff;
  font-size: 18px;
}

/*  */
.a-card {
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}
.a-card-header img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.a-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  min-height: 250px;
}

.a-card-body .tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.a-card-body .tag-teal {
  background-color: #47bcd4;
}

.a-card-body p {
  font-size: 13px;
  margin: 0 0 40px;
}
.a-card-body .user {
  display: flex;
  margin-top: auto;
}

.a-card-body .user img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.a-card-body .user-info h5 {
  margin: 0;
}
.a-card-body .user-info small {
  color: #545d7a;
}
/*  */
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
/* This pen */
.dark {
  background: #213b45;
}
.light {
  background: #f3f5f7;
}
a,
a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}
#pageHeaderTitle {
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
}
/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;
  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;
  color: #ffffff;
}
.postcard.dark {
  background-color: #18151f;
}
.postcard.light {
  background-color: #e1e5ea;
}
.postcard .t-dark {
  color: #18151f;
}
.postcard a {
  color: inherit;
}
.postcard h1,
.postcard .h1 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.postcard .small {
  font-size: 80%;
}
.postcard .postcard__title {
  font-size: 1.75rem;
}
.postcard .postcard__img {
  max-height: 180px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.postcard .postcard__img_link {
  display: contents;
}
.postcard .postcard__bar {
  width: 50px;
  height: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background-color: #424242;
  transition: width 0.2s ease;
}
.postcard .postcard__text {
  padding: 1.5rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.postcard .postcard__preview-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
  height: 100%;
}
.postcard .postcard__tagbox {
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 0;
  justify-content: center;
}
.postcard .postcard__tagbox .tag__item {
  display: inline-block;
  background: rgba(83, 83, 83, 0.4);
  border-radius: 3px;
  padding: 2.5px 10px;
  margin: 0 5px 5px 0;
  cursor: default;
  user-select: none;
  transition: background-color 0.3s;
}
.postcard .postcard__tagbox .tag__item:hover {
  background: rgba(83, 83, 83, 0.8);
}
.postcard:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(-70deg, #424242, transparent 50%);
  opacity: 1;
  border-radius: 10px;
}
.postcard:hover .postcard__bar {
  width: 100px;
}
@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;
  }
  .postcard .postcard__title {
    font-size: 2rem;
  }
  .postcard .postcard__tagbox {
    justify-content: start;
  }
  .postcard .postcard__img {
    max-width: 300px;
    max-height: 100%;
    transition: transform 0.3s ease;
  }
  .postcard .postcard__text {
    padding: 3rem;
    width: 100%;
  }
  .postcard .media.postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    background: #18151f;
    top: -20%;
    height: 130%;
    width: 55px;
  }
  .postcard:hover .postcard__img {
    transform: scale(1.1);
  }
  .postcard:nth-child(2n + 1) {
    flex-direction: row;
  }
  .postcard:nth-child(2n + 0) {
    flex-direction: row-reverse;
  }
  .postcard:nth-child(2n + 1) .postcard__text::before {
    left: -12px !important;
    transform: rotate(4deg);
  }
  .postcard:nth-child(2n + 0) .postcard__text::before {
    right: -12px !important;
    transform: rotate(-4deg);
  }
}
@media screen and (min-width: 1024px) {
  .postcard__text {
    padding: 2rem 3.5rem;
  }
  .postcard__text:before {
    content: "";
    position: absolute;
    display: block;
    top: -20%;
    height: 130%;
    width: 55px;
  }
  .postcard.dark .postcard__text:before {
    background: #18151f;
  }
  .postcard.light .postcard__text:before {
    background: #e1e5ea;
  }
}
/* COLORS */
.postcard .postcard__tagbox .green.play:hover {
  background: #fea34e;
  color: black;
}
.green .postcard__title:hover {
  color: #fea34e;
}
.green .postcard__bar {
  background-color: #fea34e;
}
.green::before {
  background-image: linear-gradient(
    -30deg,
    rgba(121, 221, 9, 0.1),
    transparent 50%
  );
}
.green:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    rgba(121, 221, 9, 0.1),
    transparent 50%
  );
}
.postcard .postcard__tagbox .red.play:hover {
  background: #fea34e;
}
.red .postcard__title:hover {
  color: #fea34e;
}
.red .postcard__bar {
  background-color: #fea34e;
}
.red::before {
  background-image: linear-gradient(
    -30deg,
    rgba(189, 21, 11, 0.1),
    transparent 50%
  );
}
.red:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    rgba(189, 21, 11, 0.1),
    transparent 50%
  );
}
.postcard .postcard__tagbox .yellow.play:hover {
  background: #fea34e;
  color: black;
}
.yellow .postcard__title:hover {
  color: #fea34e;
}
.yellow .postcard__bar {
  background-color: #fea34e;
}
.yellow::before {
  background-image: linear-gradient(
    -30deg,
    rgba(189, 187, 73, 0.1),
    transparent 50%
  );
}
.yellow:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    rgba(189, 187, 73, 0.1),
    transparent 50%
  );
}
@media screen and (min-width: 769px) {
  .green::before {
    background-image: linear-gradient(
      -80deg,
      rgba(121, 221, 9, 0.1),
      transparent 50%
    );
  }
  .green:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(121, 221, 9, 0.1),
      transparent 50%
    );
  }
  .blue::before {
    background-image: linear-gradient(
      -80deg,
      rgba(0, 118, 189, 0.1),
      transparent 50%
    );
  }
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(0, 118, 189, 0.1),
      transparent 50%
    );
  }
  .red::before {
    background-image: linear-gradient(
      -80deg,
      rgba(189, 21, 11, 0.1),
      transparent 50%
    );
  }
  .red:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(189, 21, 11, 0.1),
      transparent 50%
    );
  }
  .yellow::before {
    background-image: linear-gradient(
      -80deg,
      rgba(189, 187, 73, 0.1),
      transparent 50%
    );
  }
  .yellow:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      rgba(189, 187, 73, 0.1),
      transparent 50%
    );
  }
}
/*  */
.box-part span {
  font-size: 15px;
}

.box-part {
  border-radius: 6px;
  padding: 30px 10px;
  margin: 15px 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.box-part .text {
  margin: 10px 0px;
}

.fa {
  color: #fea34e;
  margin-bottom: 4px;
}
.card,
img {
  transition: all 0.15s ease;
}
.plan img {
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
}
.card:hover,
.shadow-img {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
</style>
