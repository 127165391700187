import { createI18n } from "vue-i18n";
import uz from "./uz";
import en from "./en";
import ru from "./ru";

const messages = {
  uz,
  en,
  ru,
};

export default createI18n({
  locale: process.env.VUE_APP_LOCALE || "uz", // set locale
  fallbackLocale: "uz", // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});
