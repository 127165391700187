import { createRouter, createWebHistory } from "vue-router";

import HomePage from "@/pages/HomePage.vue";

const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || { top: 0, left: 0 };
};

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
    },
    {
      path: "/:status",
      name: "plan",
      component: () => import("@/pages/EachPlan.vue"),
      props: true,
    },
  ],
  scrollBehavior,
});

export default router;
