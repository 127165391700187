<template>
  <div class="sub-header">
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-xs-12">
          <ul class="left-info">
            <li>
              <a href="mailto: qaswatour@mail.ru"
                ><i class="fa fa-envelope"></i>qaswatour@mail.ru</a
              >
            </li>
            <li>
              <a href="tel:+9989998651111"
                ><i class="fa fa-phone"></i>+9989 (99) 865-11-11</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <ul class="right-icons">
            <li class="">
              <a href="https://www.facebook.com/QASWATOUR/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-facebook"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                  ></path>
                </svg>
              </a>
            </li>
            <li class="ml-1">
              <a href="https://www.instagram.com/qaswatour/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-instagram"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                  <circle cx="12" cy="12" r="3"></circle>
                  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                </svg>
              </a>
            </li>
            <li class="ml-1 pr-1">
              <a href="https://t.me/qaswatour" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-telegram"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                </svg>
              </a>
            </li>
            <li style="width: 110px" class="text-white pl-3 pr-4">
              <base-dropdown
                :options="$i18n.availableLocales"
                @input="$i18n.locale = $event"
              ></base-dropdown>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <header v-if="$route.name !== 'plan'">
    <nav class="navbar navbar-expand-lg">
      <div class="container">
        <router-link class="navbar-brand d-inline-block" to="/">
          <img
            class="d-inline-block d-sm-none"
            src="@/assets/logo-img.png"
            alt=""
            style="width: 90px"
          />
          <span
            class="position-relative"
            style="
              color: #f7c815;
              margin-bottom: 12px;
              font-size: 1.6rem;
              bottom: 2px;
            "
            >Qaswa Tour</span
          >
        </router-link>
        <!-- <p>{{ $t("message.hello") }}</p> -->
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <a class="nav-link current" href="#top"
                >{{ $t("nav.home") }}
                <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about-tour">{{ $t("nav.about") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#reason">{{ $t("nav.reasons") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#detail">{{ $t("nav.details") }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tariff">{{ $t("nav.tariff") }}</a>
            </li>
            <li @click="isShowContact = true" class="nav-item">
              <a class="nav-link" href="#">{{ $t("nav.contact") }}</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="contact" :class="{ show: isShowContact }">
      <div class="container">
        <div
          class="row justify-content-between align-items-center"
          style="height: 100vh"
        >
          <div class="col-md-6">
            <div class="text-center text-md-left">
              <h2 class="fw-bold text-white mb-3">
                <b>{{ $t("nav.contact") }}</b>
              </h2>
              <div>
                <a href="tel:+9989998651111" class="d-block mb-2 text-white"
                  ><i class="fa fa-phone mr-2"></i>+9989 (99) 865-11-11</a
                >
                <a href="mailto: qaswatour@mail.ru" class="text-white"
                  ><i class="fa fa-envelope mr-2"></i>qaswatour@mail.ru</a
                >
                <h6 class="mt-3 mb-2 text-white">
                  {{ $t("messages.address") }}
                </h6>
                <span style="color: white; font-size: 0.8rem">
                  TOSHKENT SHAHRI, ЯККАСАРОЙ ТУМАНИ, <br />
                  ДАМАРИК МФЙ, АБДУЛЛА КАХХОР КУЧАСИ, <br />
                  2А-УЙ
                </span>
                <ul class="social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/QASWATOUR/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-brand-facebook"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                        ></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/qaswatour/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-brand-instagram"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                        <circle cx="12" cy="12" r="3"></circle>
                        <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://t.me/qaswatour" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-brand-telegram"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path
                          d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"
                        ></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h2 class="fw-bold text-white mb-3">
              <b>{{ $t("nav.contact") }}</b>
            </h2>
            <form @submit.prevent="" id="contact footer-contact" class="footer">
              <div class="row">
                <div
                  class="col-lg-12 col-md-12 col-sm-12 mb-3"
                  :class="{ error: isEmpty && !firstName }"
                >
                  <fieldset>
                    <input
                      v-model="firstName"
                      name="name"
                      type="text"
                      class="form-control"
                      id="name"
                      :placeholder="`${$t('form.firstName')}`"
                    />
                  </fieldset>
                </div>
                <div
                  class="col-lg-12 col-md-12 col-sm-12"
                  :class="{ error: isEmpty && !lastName }"
                >
                  <fieldset>
                    <input
                      v-model="lastName"
                      name="name"
                      type="text"
                      class="form-control"
                      id="lastName"
                      :placeholder="`${$t('form.lastName')}`"
                    />
                  </fieldset>
                </div>
                <div
                  class="col-lg-12 col-md-12 col-sm-12 my-3"
                  :class="{ error: isEmpty && !phoneNumber }"
                >
                  <fieldset>
                    <input
                      v-model="phoneNumber"
                      type="text"
                      class="form-control"
                      id="phoneNumber"
                      placeholder="(90) 900-00-00"
                    />
                  </fieldset>
                </div>
                <div class="col-lg-12" :class="{ error: isEmpty && !message }">
                  <fieldset>
                    <textarea
                      v-model="message"
                      name="message"
                      rows="6"
                      class="form-control"
                      id="message"
                      :placeholder="$t('form.message')"
                    ></textarea>
                  </fieldset>
                </div>
                <div class="col-lg-12 mt-3">
                  <button
                    @click="sendMessage"
                    type="submit"
                    class="btn btn-primary float-right"
                  >
                    {{ $t("messages.send") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div @click="isShowContact = false" class="close-contact">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-arrow-bar-to-right"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="#fff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1="14" y1="12" x2="4" y2="12"></line>
          <line x1="14" y1="12" x2="10" y2="16"></line>
          <line x1="14" y1="12" x2="10" y2="8"></line>
          <line x1="20" y1="4" x2="20" y2="20"></line>
        </svg>
      </div>
    </div>
  </header>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown.vue";
import customAxios from "@/api";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    BaseDropdown,
  },
  data() {
    return {
      isShowContact: false,
      isEmpty: false,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      message: "",
    };
  },
  computed: {
    resolvedNumber() {
      return "+998" + this.phoneNumber.replace(/[() \s-]+/g, "");
    },
  },
  methods: {
    async sendMessage() {
      if (
        !this.firstName ||
        !this.lastName ||
        !this.phoneNumber ||
        !this.message
      ) {
        this.isEmpty = true;
        return;
      }
      try {
        await customAxios.post("/contact/", {
          first_name: this.firstName,
          last_name: this.lastName,
          phone_number: this.resolvedNumber,
          text: this.message,
        });
        toast.success(this.$t("form.sMessage"));
        this.isEmpty = false;
        this.firstName = "";
        this.lastName = "";
        this.phoneNumber = "";
        this.message = "";
      } catch (e) {
        if (e?.response?.status == 500) {
          toast.error(this.$t("form.serverError"));
        } else {
          toast.error(this.$t("form.error"));
        }
      }
    },
    enforcePhoneFormat(val) {
      if (!val) return;
      let x = val.replace(/\D/g, "").match(/(\d{0,2})(\d{0,3})(\d{0,4})/);
      x = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      if (x.length > 11) {
        x = x.substring(0, 11) + "-" + x.substring(11);
      }
      return x;
    },
  },
  watch: {
    phoneNumber(val) {
      this.phoneNumber = this.enforcePhoneFormat(val);
    },
  },
};
</script>

<style scoped>
.contact {
  background: var(--main-color);
  position: fixed;
  opacity: 0;
  width: 100%;
  left: 100%;
  height: 100vh;
  top: 0;
  z-index: 1000000;
  transform-origin: right;
  transition: all 0.6s ease;
}
.contact.show {
  opacity: 1;
  left: 0;
}
.close-contact {
  position: absolute;
  top: 10%;
  right: 10%;
  cursor: pointer;
}
.error input,
.error textarea {
  border: 2px solid #dc3545 !important;
}
.error input::placeholder,
.error textarea::placeholder {
  color: #dc3545;
}
</style>
