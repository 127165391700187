<template>
  <footer class="mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-4 footer-item">
          <h4>Qaswa Tour</h4>
          <p>Biz bilan sayohatingiz oson va qulay bolishiga yordam beramiz!</p>
          <ul class="social-icons">
            <li>
              <a href="https://www.facebook.com/QASWATOUR/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-facebook"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                  ></path>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/qaswatour/" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-instagram"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <rect x="4" y="4" width="16" height="16" rx="4"></rect>
                  <circle cx="12" cy="12" r="3"></circle>
                  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"></line>
                </svg>
              </a>
            </li>
            <li>
              <a href="https://t.me/qaswatour" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-brand-telegram"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M15 10l-4 4l6 6l4 -16l-18 7l4 2l2 6l3 -4"></path>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-md-2 footer-item m-0 p-0"></div>
        <div class="col-md-3 footer-item p-0"></div>
        <div class="col-md-3 footer-item last-item">
          <h4>{{ $t("nav.contact") }}</h4>
          <div class="contact-form">
            <a href="tel:+9989998651111" class="d-block mb-2 text-white"
              ><i class="fa fa-phone mr-2"></i>+9989 (99) 865-11-11</a
            >
            <a href="mailto: qaswatour@mail.ru" class="mb-3 text-white"
              ><i class="fa fa-envelope mr-2"></i>qaswatour@mail.ru</a
            >
            <h6 class="mt-3 mb-2">{{ $t("messages.address") }}</h6>
            <span style="font-size: 0.8rem">
              TOSHKENT SHAHRI, ЯККАСАРОЙ ТУМАНИ, ДАМАРИК МФЙ, АБДУЛЛА КАХХОР
              КУЧАСИ, 2А-УЙ
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="sub-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <p>
            {{ $t("messages.copy") }} &copy; <span id="year"></span> Qaswa Tour.
            <a href="https://iitf.uz/" target="_blank" class="underline"
              >IITF</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
