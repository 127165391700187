<template>
  <div class="custom-selectt" :tabindex="tabindex" @blur="open = false">
    <div
      class="selectedd"
      :class="[
        { open: open },
        { 'border-danger border': isError && selected == defaultVal },
      ]"
      @click="open = !open"
    >
      <img :src="flags[selected]" style="height: 20px" class="mr-2" alt="" />
      {{ selected }}
    </div>
    <div class="itemss" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
        "
        class="d-flex align-items-center"
      >
        <img :src="flags[option]" style="height: 20px" class="mr-2" alt="" />
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    defaultVal: {
      type: String,
      required: false,
      default: null,
    },
    tt: {
      required: false,
    },
    isError: {
      required: false,
    },
    multiselect: {
      required: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    withObj: {
      required: false,
    },
    submitted: {
      required: false,
    },
  },
  data() {
    return {
      selected: this.defaultVal
        ? this.defaultVal
        : this.options.length > 0
        ? this.options[2]
        : null,
      open: false,
      flags: {
        en: require("@/assets/en.png"),
        uz: require("@/assets/uz.png"),
        ru: require("@/assets/ru.png"),
      },
    };
  },
  watch: {
    submitted() {
      if (this.submitted === true) {
        this.selected = this.default;
        this.$emit("changee");
      }
    },
  },
};
</script>

<style scoped>
.custom-selectt {
  margin-top: -1px;
  position: relative;
}

.custom-selectt .selectedd {
  border-radius: 8px;
  color: white;
  cursor: pointer;
  user-select: none;
}
.custom-selectt .selectedd.open {
  border-radius: 8px 8px 0px 0px;
}
.custom-selectt .selectedd:after {
  position: absolute;
  content: "";
  transition: all 0.2s ease;
  top: 47%;
  right: -15px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #808080 transparent transparent transparent;
}
.custom-selectt .selectedd.open:after {
  top: 35%;
  transform: rotate(180deg);
}
.custom-selectt .itemss {
  border-radius: 0px 0px 6px 6px;
  max-height: 14rem;
  overflow-y: auto;
  position: absolute;
  background: #213b45;
  width: 155%;
  left: -13px;
  right: 0;
  z-index: 1000;
  border-top: 1px solid rgba(172, 155, 155, 0.705);
}
::-webkit-scrollbar {
  display: none;
}
.custom-selectt .itemss div {
  position: relative;
  /* padding: 6px 12px; */
  color: white;
  /* border-top: 0.1px solid #ced4da; */
  padding-left: 1em;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.custom-selectt .itemss div:hover {
  background-color: rgba(128, 129, 133, 0.3);
}
.selectHide {
  display: none;
}
#x-circle {
  background: #ced4da;
  font-size: 14px;
  padding: 1px 6px 2px;
}
</style>
